import { Translate } from "./type";

export const Deutsch:Translate = {
    'email is a required field': "E-Mail ist ein Pflichtfeld",
    'password is a required field': "Passwort ist ein Pflichtfeld",
    'copy address': 'Adresse kopieren',
    'Workshop and Schuhlungen': 'Workshop und Schulungen',
    kontakt: 'Kontakt',
    partner: 'Partner',
    geschichte: 'Geschichte',
    team: 'Team',
    'uber uns': 'Über uns',
    'workshop & schuhlungen': 'Workshops & Schulungen',
    '3D druck': '3D-Druck',
    maschinenservice: 'Maschinenservice',
    kataloge: 'Kataloge',
    angebot: 'Angebot',
    geschlossen: 'Geschlossen',
    uhr: 'Uhr',
    'samstag und sonntag': 'Samstag und Sonntag',
    freitag: 'Freitag',
    'montag - donnerstag': 'Montag - Donnerstag',
    impressum: 'Impressum',
    'schuh - und orthopädiebedarf': 'Schuh - und Orthopädiebedarf',
    'rudolf roth ag': 'Rudolf Roth AG',
    'anmelden oder login beantragen': 'Anmelden oder Login beantragen',
    logout: 'Logout',
    webshop: 'Webshop',
    schuhlungen: 'Schulungen',
    'unser team': 'Unser Team',
    'mein konto': 'Mein Konto',
    'erledigte bestellungen': 'Erledigte Bestellungen',
    'pendente bestellungen': 'Pendente Bestellungen',
    rückgaben: 'Rückgaben',
    'defekt melden': 'Reklamation',
    accountangaben: 'Accountangaben',
    'änderungen speichern': 'Änderungen speichern',
    rechnungsadresse: 'Rechnungsadresse',
    'plz': 'PLZ',
    'ein land': 'Ein Land',
    'die stadt': 'Ort',
    adresse: 'Adresse',
    kundennummer: 'Kundennummer',
    email: 'Email',
    'kennwort ändern': 'Kennwort ändern',
    'neue adresse hinzufügen': 'Neue Adresse hinzufügen',
    kontodetails: 'Kontodetails',
    firma: 'Firma',
    kontaktperson: 'Kontaktperson',
    telefonnummer: 'Telefonnummer',
    'zusatzliche telefonnummer': 'Zusätzliche Telefonnummer',
    'gln-nummer': 'GLN-Nummer',
    'mwst-nummer': 'MwSt-Nummer',
    lieferadresse: 'Lieferadresse',
    'bestellung wiederholen': 'bestellung wiederholen',
    bezahlstatus: "bezahlstatus",
    gesamtbetrag: "gesamtbetrag",
    size: "größe",
    colour: "farbe",
    'melden sie sich in ihrem konto an': 'melden sie sich in ihrem konto an',
    email_login: 'email',
    'email oder Kundennummer': 'e-mail oder kundennummer',
    password: 'passwort',
    'nicht registriert?': 'nicht registriert?',
    'ein Konto erstellen': 'ein Konto erstellen',
    'passwort vergessen?': 'passwort vergessen?',
    'leer': 'leer',
    'wird geladen': 'wird geladen',
    "willkürlich": "willkürlich",
    "festgelegt": "festgelegt",
    "min length 1": "Mindestlänge 1",
    'bilder hochladen': 'bilder hochladen',
    'bestellnummer': 'bestellnummer',
    'defektbeschreibung': 'Beschreibung',
    delete: 'delete', 
    'in den warenkorb': 'in den warenkorb',
    'wonach suchst du?': 'wonach suchst du?',
    category: 'kategorie',
    'leerer warenkorb': 'Warenkorb leeren,',
    'zurück zum einkaufen': 'Zurück zum Shop',
    bestellen: 'bestellen',
    'zum warenkorb hinzufügen': 'In den Warenkorb',
    'menge auf lager':'menge auf lager',
    favoritinnen: 'Merkliste',
    kasse: 'Bestellung',
    versandart: 'versandart',
    lieferung: 'lieferung',
    'Lieferadresse wählen': 'Lieferadresse wählen',
    'Andere Adresse': 'Andere Adresse', 
    rezension: 'Rezension',
    zahlung: 'Zahlung',
    "We’re on it!": "Vielen Dank für Ihre Bestellung!",
    'Your payment has been received and you\'ll get notifications for your order\'s state.': 'Ihre Zahlung ist eingegangen und Sie erhalten Benachrichtigungen zum Status Ihrer Bestellung.',
    'Zurück zum Einkaufen': 'Zurück zum Shop',
    'Something went wrong': 'Etwas ist schiefgelaufen',
    'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.': 'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.',
    "versand": "Versand",
    "post": "Post",
    "warenkorb": "Warenkorb",
    "Total": "Gesamt",
    "List": "Liste",
    "Price": "Preis",
    "Quantity": "Menge",
    "Total Price": "Gesamtpreis",
    "color": "Farbe",
    "Rechnung erhalten per": "Rechnung erhalten per",
    "Versandart": "Versandart",
    "List of products": "Produktliste",
    "Photo": "Bild",
    "Warenkorb": "Warenkorb",
    'Unsere Partner': 'Unsere Partner',
    'Schulungen': 'Schulungen',
    Kataloge: 'Kataloge',
    Hauptkatalog: 'Hauptkatalog',
    Gummi: 'Gummi',
    Orthopädie: 'Orthopädie',
    "Leder": "Leder",
    "Chemie": "Chemie",
    "Beschläge": "Beschläge",
    "Schuhpflege": "Schuhpflege",
    "Werkzeuge": "Werkzeuge",
    "Maschinen": "Maschinen",
    Firma: "Firma",
    vorname: "Vorname",
    "Treten sie mit uns in kontakt": "Treten Sie mit uns in Kontakt",
    nachname: "Nachname",
    strasse: "Strasse",
    'nr.': "Nr.",
    'nr': "Nr.",
    postcode: "Post",
    ort: "Ort",
    phone: "Telefon",
    "Sie uns Ihr anliege": "Sie uns Ihr anliege",
    "Rudolf Roth AG": "Rudolf Roth AG",
    "Schuh - und Orthopädiebedarf": "Schuh - und Orthopädiebedarf",
    "Telefondienst": "Telefondienst",
    "Montag - Donnerstag": "Montag - Donnerstag",
    Freitag: "Freitag",
    "7.45 Uhr- 12.00 Uhr": "7.45 Uhr - 12.00 Uhr",
    "13.00 Uhr - 17.15 Uhr": "13.00 Uhr - 17.15 Uhr",
    "13.00 Uhr - 16.45 Uhr": "13.00 Uhr - 17.45 Uhr",
    "Samstag und Sonntag": "Samstag und Sonntag",
    Geschlossen: "Geschlossen",
    Senden: "Senden",
    "Maschinenservicek": "Maschinenservice",
    "Ein Maschinenservice-Anforderungsformular": "Formular für Maschinenservice-Anforderung",
    "name": "Name",
    "maschinenbezeichnung": "Maschinenbezeichnung",
    "Maschinennummer": "Maschinennummer",
    "Problem Beschreibung": "Problem Beschreibung",
    "Maschinen service": "Maschinenservice",
    "Workshop and Schulungen": 'Workshop und Schulungen',
    kataloge_1: 'Unser Hauptkatalog besteht aus 9 Teilen. Durch Klicken auf das entsprechende Bild oder den Text gelangen Sie jeweils zu einer PDF-Datei. Diese Kataloge werden laufend aktualisiert.',
    'Anfrageformular für einen Workshop': 'Anfrageformular für einen Workshop',
    'Anfrageformular für 3D-Druck': 'Anfrageformular für 3D-Druck',
    "Geschichte_1": "Die Rudolf Roth AG in Gwatt-Thun (Orthopädiebedarf, Schuhpflege, Lederpflege) ist seit 1949 ein kompetenter Ansprechpartner für Schuhmacher, orthopädische Werkstätten, Schuhfach- und Sportgeschäfte, ein Familienunternehmen.",
    "Geschichte_2": "Als schweizweit bekannter Spezialist vertreibt die Firma alle in der Schuhbranche verwendeten Artikel aus den Bereichen Gummi, Orthopädie, Leder, Chemische Artikel, Beschläge, Reparaturteile, Garne, Furnituren, Werkzeuge, Maschinen und Maschinenteile.",
    "Geschichte_3": "Die Pflege der Geschäftsbeziehungen durch den persönlichen Kontakt zum Kunden gehört ebenso zur seriösen Geschäftsphilosophie wie die hohe Qualität der Produkte und die Belieferung der Kunden innerhalb kurzer Frist.",
    'Ihr Warenkorb': 'Ihr Warenkorb',
    'Nettobetrag': 'Nettobetrag',
    'MWST':'MwSt',
    'Stärke':'Stärke',
    'Shore':'Shore',
    'Maßeinheiten':'Maßeinheiten',
    'WAITING': 'WARTEND',
    'OPENED': 'GEÖFFNET',
    'ISSUED': 'AUSGESTELLT',
    'CANCELED': 'STORNIERT',
    'CLOSED': 'GESCHLOSSEN',
    'NORMAL': 'NORMAL',
    'COMPLETED': 'Abgeschlossen',
    welcomeText: "Willkommen auf unserer Seite 'Schulungen'!",
    welcomeDescriptionPart1: "Wir freuen uns, Ihnen Workshops und Schulungen nach Ihren Bedürfnissen anzubieten. Ob Sie Ihre Klebstoffanwendung optimieren möchten, sehen welche Möglichkeiten es mit modernem Fräs- und Aufbaumaterial gibt, oder lernen, wie man 3D-gedruckte Orthesen anfertigt – bei uns sind Sie an der richtigen Adresse.",
    welcomeDescriptionPart2: "Unser Ziel ist es, Ihnen das Wissen und die Fähigkeiten zu vermitteln, sicher und kompetent mit unseren Produkten und Dienstleistungen zu arbeiten, unabhängig von Ihrem Erfahrungsstand. Gerne kommen häufig auch die Anwendungstechniker von Renia, Topy und anderen Partner zusammen mit uns zu Ihnen. Zusätzlich bieten wir auch Verkaufsschulungen im Bereich Pflegeprodukte für Schuh- und Sportgeschäfte an. Sie möchten gerne mehr Umsatz mit Zusatzverkäufen in Ihrem Geschäft generieren? Kein Problem!",
    welcomeDescriptionPart3: "Um Ihre Wünsche bestmöglich berücksichtigen zu können, bitten wir Sie, unser Online-Formular auszufüllen. Teilen Sie uns darin Ihren Namen, gegebenenfalls den Firmennamen, Ihre Telefonnummer, E-Mail-Adresse und Ihre Adresse mit. Nutzen Sie das Nachrichtenfeld, um uns mitzuteilen, welche Schulung Sie interessiert oder welche spezifischen Themen Sie vertiefen möchten. Sollten Sie daran interessiert sein, für die Schulungen Credits zu erhalten, informieren wir Sie gerne über die entsprechenden Möglichkeiten. Wir freuen uns darauf, Sie auf Ihrem Lernweg zu unterstützen und gemeinsam mit Ihnen Ihre Expertise auszubauen!",
    'Umsatz im Bereich Pflegeprodukte mit einer Verkaufsschulung steigern':'Umsatz im Bereich Pflegeprodukte mit einer Verkaufsschulung steigern',
    druckTextPart1:'Willkommen auf unserer Seite für 3D-Druck-Anfragen speziell für Orthesen! Wir bieten individuell angepasste Orthesen, die mit Präzision und Sorgfalt gefertigt werden. Um den Prozess zu starten, füllen Sie bitte das Formular auf dieser Seite aus. So stellen wir sicher, dass wir alle notwendigen Informationen haben, um eine Lösung zu entwickeln, die perfekt auf Ihre Bedürfnisse abgestimmt ist.',
    druckTextPart2: 'Bitte geben Sie Ihren vollständigen Namen, Ihre Telefonnummer, Ihre E-Mail-Adresse sowie Ihre Adresse an. Falls Sie im Namen eines Unternehmens handeln, ergänzen Sie bitte den Firmennamen',
    druckTextPart3: 'Nutzen Sie das Nachrichtenfeld, um Details zu Ihrer Anfrage zu beschreiben. Teilen Sie uns z. B. den Verwendungszweck der Orthese oder besondere Anforderungen mit.',
    druckText: 'Materialoptionen',
    druckDescriptionPart1: 'Wählen Sie aus den folgenden Materialien das passende für Ihre Orthese:',
    druckDescriptionPart2: 'Leicht, flexibel und langlebig — ideal für Anwendungen mit geringer Belastung.',
    druckDescriptionPart3: 'Robust, schlagfest und lebensmittelecht — optimal für den täglichen Gebrauch.',
    druckDescriptionPart4: 'Elastisch, flexibel und verschleissfest — perfekt für dynamische oder weiche Orthesenkomponenten.',
    druckDescriptionPart5: 'Mit Kohlenstofffasern verstärkt für maximale Festigkeit und Steifigkeit — ideal für hochbelastbare Anwendungen.',
    druckDescriptionPart6: 'Falls Sie bereits eine gescannte STL-Datei der betreffenden Extremität besitzen, können Sie diese direkt hochladen. Wenn Sie keinen Scan haben, teilen Sie uns dies bitte in der Nachricht mit. Wir setzen uns dann mit Ihnen in Verbindung, um einen Termin für den Scan zu vereinbaren. Nach Eingang Ihrer Anfrage wird unser Team diese prüfen und sich zeitnah mit Ihnen in Verbindung setzen, um die Details zu besprechen und die nächsten Schritte zu planen.',
    druckDescriptionPart7: 'Vielen Dank, dass Sie sich für unseren 3D-Druckservice entschieden haben. Wir freuen uns darauf, eine massgeschneiderte Orthese für Sie zu erstellen, die perfekt auf Ihre Anforderungen abgestimmt ist!',
    druckDescriptionPart8: 'PP (Polypropylen):',
    druckDescriptionPart9: 'TPU (Thermoplastisches Polyurethan):',
    druckDescriptionPart10: 'PETG (Polyethylenterephthalat Glykol):',
    druckDescriptionPart11: 'PETG-Carbon:',
    HARDO: 'HARDO',
    maschinenserviceTextPart1: 'Bitte füllen Sie das folgende Formular aus, wenn Sie ein Problem mit Ihrer Maschine haben, sei es eine Störung, ein defektes Teil oder ein anstehender Filterservice. Teilen Sie uns den Namen der Maschine, Ihre Kontaktdaten und eine detaillierte Beschreibung des Problems mit. Falls möglich, laden Sie relevante Bilder hoch, um uns bei der Einschätzung zu unterstützen. Unser Team wird Ihre Anfrage umgehend prüfen und sich zeitnah mit Ihnen in Verbindung setzen, um einen Termin mit einem unserer Techniker zu vereinbaren.',
    Bestellung: 'Bestellung',
    'Auf-lager': 'Auf lager',
    Materialoptionen:'Materialoptionen',
    PP: 'PP',
    TPU: 'TPU',
    PETG: 'PETG',
    'PETG-Carbon':'PETG-Carbon',
};
